import React from 'react';
import { Link } from 'gatsby';

import SEO from '@components/seo';
import '@styles/styles.scss';

const ThanksPage = () => (
  <React.Fragment>
    <SEO title="Page not found" />
    <div className="flex-center flex-column" style={{
      width: '100vw',
      height: '100vh',
      background: '#f8f8f8'
    }}>
      <h1>Thanks! Your booking is confirmed</h1>
      <Link to={'/'}>Home</Link>
    </div>
  </React.Fragment>
);

export default ThanksPage;
